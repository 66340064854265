<template>
	<div class="main-content">
		<el-form inline label-width="100px">
            <button-permissions :datas="'addVersion'">
                <el-form-item>
                    <el-button type="primary" @click="addItem">发布版本</el-button>
                </el-form-item>
            </button-permissions>
		</el-form>

		<el-table :data="list" v-loading="tableLoading" border>
			<el-table-column prop="title" label="端口">
                <template slot-scope="scope">
					<span v-if="scope.row.applePort">IOS</span>
                    <span v-if="scope.row.applePort && scope.row.androidPort">、</span>
                    <span v-if="scope.row.androidPort">Android</span>
				</template>
            </el-table-column>
			<el-table-column prop="appVersion" label="版本"></el-table-column>
            <el-table-column label="是否强制更新">
                <template slot-scope="scope">
					<el-tag v-if="scope.row.isForcedUpdate == 1" type="" effect="dark"> 是 </el-tag>
					<el-tag v-else type="info" effect="dark"> 否 </el-tag>
				</template>
            </el-table-column>
			<el-table-column prop="createTime" label="发布时间"></el-table-column>
			<el-table-column label="操作" width="160">
				<template slot-scope="scope">
                    <el-button type="text" class="pointers" style="margin-right:10px;" @click="openVersionDesc(scope)">更新内容</el-button>
                    <button-permissions :datas="'editVersion'">
                        <el-button type="text" class="pointers" style="margin-right:10px;" @click="editItem(scope)">编辑</el-button>
                    </button-permissions>
                    <button-permissions :datas="'delVersion'">
                        <el-button type="text" class="pointers" @click="deleteItem(scope)">删除</el-button>
                    </button-permissions>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination 
            style="text-align: right; padding: 15px" 
            :current-page="record.pageNo" 
            :page-size="record.pageSize"
			:total="total" 
            @size-change="pageSizeChange"
			@current-change="currentChange" 
            layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>

		<!--  -->
		<el-dialog :title="addEditTitle" :visible.sync="addEditFlag" width="800px" @closed="clearDataFunc">
			<AddEdit v-if="addEditData" :formData="addEditData" @closeLayer="closedLayer" ></AddEdit>
		</el-dialog>
        <!-- 更新内容 -->
        <el-dialog title="更新内容" :visible.sync="versionDesc" width="400px">
			<div>
                更新内容
            </div>
            <div style="margin-top:20px;">
                {{desc.appVersion}}
            </div>
            <div style="margin-top:20px;">
                {{desc.versionDescription}}
            </div>
		</el-dialog>
	</div>
</template>

<script>
	import { findPageAppVersion,delAppVersion} from '@/api/setting';
	import AddEdit from './addEdit';
	export default {
		components: {
			AddEdit,
		},
		data() {
			return {
				tableLoading: false,
				record: {
					pageNo: 1,
					pageSize: 10,
				},
                total: 0,
                list: [],   //数据列表
				addEditTitle: '发布版本',
				addEditFlag: false,
				addEditData: null,
                desc:{},    //更新内容
                versionDesc:false,  //更新内容
			}
		},
		mounted() {
            this.getList();
		},
		methods: {
			pageSizeChange(size) {
				this.record.pageSize = size;
				this.getList();
			},
			currentChange(page) {
				this.record.pageNo = page;
				this.getList();
			},
			// 获取列表
			async getList() {
				this.tableLoading = true;
				let params = this.record;
				let { success, data } = await findPageAppVersion(params);
				if (success) {
					this.list = data.records || [];
					this.total = data.total;
				}
				setTimeout(() => {
					this.tableLoading = false;
				}, 300);
			},
			addItem() {
				this.addEditFlag = true;
				this.addEditTitle = '发布版本';
				this.addEditData = {
                    androidPort:0,     //是否是安卓，0否1是
					androidPackage: null,  //安卓安装包大小
                    androidType:1,     //安装包大小单位，1=MB，2=GB
                    androidStore: "",   //安卓地址
                    appVersion: "",     //appVersion
                    applePort:0,       //是否是apple，0否1是
                    applePackage: null,    //apple安装包大小
                    appleType:1,        //安装包大小单位，1=MB，2=GB
                    appleStore: "",     //apple下载地址
                    isForcedUpdate: 0,  //是否强制更新0：否，1：是
                    isHotUpdate: 0,  //是否热更新0：否，1：是
                    versionDescription: "", //更新描述
				};
			},
			//编辑/发布版本
			editItem({row}) {
				this.addEditTitle = '编辑版本';
                let temp = JSON.parse(JSON.stringify(row));
                temp.isForcedUpdate = !!temp.isForcedUpdate;
                temp.isHotUpdate = !!temp.isHotUpdate;
                temp.androidPort = !!temp.androidPort;
                temp.applePort = !!temp.applePort;
				this.addEditData = {...temp};
                console.log(this.addEditData);
                this.addEditFlag = true;
			},
			//删除版本
			async deleteItem({row}) {
                this.$confirm('删除后不可恢复，是否确认确认继续删除?', '提示', {}).then(async () => {
                    let result = await delAppVersion({id:row.id});
                    if (result.success) {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.getList();
                        
                    } else {
                        this.$message({
                            type: 'error',
                            message: result.alertMsg
                        });
                    }
                })
			},
			//关闭弹出层
			closedLayer(flag) {
				this.addEditFlag = false;
				if(flag) {
					this.getList();
				}
			},
			clearDataFunc() {
				this.addEditData = null;
			},
            //打开弹窗
            openVersionDesc(e){
                this.desc = e;
                this.versionDesc = true;
            }
		}
	}
</script>

<style scoped lang="less">
	.iv-heaer>img {
		width: 50px;
		height: 50px;
	}
</style>