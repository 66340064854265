<template>
    <div>
        <el-form :model="formData" ref="addEditForm" label-width="160px">
            <el-form-item label="端口：">
                <el-checkbox v-model="formData.applePort">IOS</el-checkbox>
                <el-checkbox v-model="formData.androidPort">Andriod</el-checkbox>
            </el-form-item>
            <el-form-item label="版本号：" prop="appVersion" :rules="[{required:true,message:'版本号不能为空',trigger: 'blur'}]">
                <el-input v-model="formData.appVersion" placeholder="请输入" maxlength="20"></el-input>
                <div>版本号必须比上一个版本号大，否则会员无法正常更新</div>
            </el-form-item>
            <el-form-item label="AppStore地址：" prop="appleStore" v-if="formData.applePort" :rules="[{required:true,message:'AppStore地址不能为空',trigger: 'blur'}]">
                <el-input v-model="formData.appleStore" placeholder="请输入" maxlength="20"></el-input>
                <div>通过App store应用商城找到自己的APP，然后复制下载地址填写到文本框</div>
            </el-form-item>
            <el-form-item label="苹果安装包大小：" prop="applePackage" v-if="formData.applePort" :rules="[{required:true,message:'苹果安装包大小不能为空',trigger: 'blur'}]">
                <el-input placeholder="请输入内容" v-model="formData.applePackage" class="input-with-select">
                    <el-select v-model="formData.appleType" slot="append" placeholder="请选择" style="width:75px;">
                        <el-option label="MB" :value="1"></el-option>
                        <el-option label="GB" :value="2"></el-option>
                    </el-select>
                </el-input>
                <div>该信息展示在APP下载页</div>
            </el-form-item>

            <el-form-item label="安卓APK地址：" prop="androidStore" v-if="formData.androidPort" :rules="[{required:true,message:'安卓APK地址不能为空',trigger: 'blur'}]">
                <el-input placeholder="请输入内容" v-model="formData.androidStore" class="input-with-select">
                    <el-button type="primary" style="width:100px;color: #FFF;background-color: #409EFF;border-radius:0 5px 5px 0;height:36px;" slot="append">上传文件</el-button>
                </el-input>
                <div>可使用第三方应用平台地址，也可直接上传APK包</div>
            </el-form-item>
            <el-form-item label="安卓安装包大小：" prop="androidPackage" v-if="formData.androidPort" :rules="[{required:true,message:'安卓安装包大小不能为空',trigger: 'blur'}]">
                <el-input placeholder="请输入内容" v-model="formData.androidPackage" class="input-with-select">
                    <el-select v-model="formData.androidType" slot="append" placeholder="请选择" style="width:75px;">
                        <el-option label="MB" :value="1"></el-option>
                        <el-option label="GB" :value="2"></el-option>
                    </el-select>
                </el-input>
                <div>该信息展示在APP下载页</div>
            </el-form-item>
            <el-form-item label="更新内容：" prop="versionDescription" :rules="[{required:true,message:'更新内容不能为空',trigger: 'blur'}]">
                <el-input placeholder="请输入内容" type="textarea" v-model="formData.versionDescription"></el-input>
                <div>需要换行请回车</div>
            </el-form-item>
            <el-form-item label="是否是热更新：" prop="content">
                <el-switch v-model="formData.isHotUpdate"></el-switch>
            </el-form-item>
            <el-form-item label="强制更新：" prop="content">
                <el-switch v-model="formData.isForcedUpdate"></el-switch>
                <div>开启后旧版本的会员必须更新APP才能正常使用APP</div>
            </el-form-item>
            <div style="text-align:right;">
                <el-button @click="cancelAddEdit">取消</el-button>
                <el-button type="primary" @click="saveAddEdit">确认</el-button>
            </div>
        </el-form>
       
    </div>
</template>

<script>
    import { 
        addAppVersion, 
        updateAppVersion 
    } from "@/api/setting";
	export default {
        components: {
            
        },
		props: {
			formData: {
				type: Object,
				default: null,
			},
            supplierList:{
                type: Array
            }
		},
		data() {
			return {
                
			}
		},
        mounted() {

		},
		methods: {
			//取消
			cancelAddEdit() {
				this.$refs["addEditForm"].clearValidate();
				this.$emit('closeLayer', false);
			},
			//确认提交
			async saveAddEdit() {
                let _this = this;
                if (!_this.formData.applePort && !_this.formData.androidPort) {
                    this.$message({
                        type: 'error',
                        message: '至少选择一个端口类型！'
                    });
                }
                this.$refs["addEditForm"].validate(async (valid) => {
                    if (valid) {
                        let params = { ...this.formData};
                        params = JSON.parse(JSON.stringify(params));
                        let res = {};
                        params.isForcedUpdate = params.isForcedUpdate ? 1 : 0;
                        params.isHotUpdate = params.isHotUpdate ? 1 : 0;
                        params.applePort = params.applePort ? 1 : 0;
                        params.androidPort = params.androidPort ? 1 : 0;
                        if(params.id) {
                            res = await updateAppVersion(params);
                        } else {
                            res = await addAppVersion(params);
                        }
                        if(res.success) {
                            this.$emit('closeLayer', true);
                        }else{
                            this.$message({
                                type: 'error',
                                message: res.alertMsg
                            });
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
				
			},
		}
	}
</script>

<style lang="less" scoped>
.container {
	// padding: 10px;
	width: 100%;
	overflow: hidden;

	/deep/.el-textarea {
		width: 500px;
	}
	/deep/.el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		height: 146px;
	}

	/deep/.el-upload:hover {
		border-color: #409eff;
	}
}
</style>